<template>
  <main class="dashboard">
    <div v-if="reconnecting" class="reconnecting">Reconnecting...</div>

    <section class="starting" v-if="current.status == 0">
      <a href="/upcoming" style="display:block; text-decoration:none; position: fixed; left:24px;top:95px; font-size:18px; color:black;" title="Back to session list">
          <img src="../assets/arrow_l.svg" style="width:16px;height:16px;vertical-align:middle;" /> <span style="vertical-align:middle;">Back to dashboard</span>
      </a>
      <div>
      <span style="font-size: 30px; line-height: normal; display:block;">
        Starting a session means the session is<br>now live and broadcast to your learners.
      </span>      
      <span style="font-size: 20px; color: #777; padding-top:32px; padding-bottom: 40px; line-height: normal; display:block;">
        Your organization’s credits will be updated according<br>to the number of learners from this point.
      </span>
      <div style="display:flex; justify-content:center; align-items:center; gap:20px; margin-bottom: 25px;" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
        <ToggleButton v-model="testMode" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" :sync="true" /> 
        <div style="dispaly:block; text-align:left; font-size:14px;">Test Mode <br><span style="color: #777; font-size:14px;">(no credits used, control up to 3 devices)</span></div>
      </div>
      <button @click="checkSession" style="margin: 0 auto;">
        <img src="../assets/play1.svg" style="width: 18px; height:18px; vertical-align:middle; margin-right: 8px;"> Launch session{{selectedSession && testMode ? ' in test mode' : ''}}
      </button>      
      </div>
      <div>
        <div style="text-align:left; font-size:20px; font-weight: bold; padding-bottom:35px;">Don't forget</div>
        <div style="display:flex; text-align:left;">
          <img src="../assets/teach.svg" style="width:60px; margin-right:15px;" />
          <div style="flex-grow:1; padding-right:48px;">
            <b>Explain</b>
            <br>
            <span style="color: #777;">Explain to the learners how to use the devices.</span>
            <br>
            <a href="https://video.insead.edu/media/t/1_nthe3kjq" target="_blank" >For video tutorial, click here</a>
          </div>

          <img src="../assets/vr_glasses.svg" style="width:60px; margin-right:15px;" />
          <div style="flex-grow:1; padding-right:48px;">
            <b>Observe</b>
            <br>
            <span style="color: #777;">Has everyone put on their headsets AND earphones?</span>
          </div>

          <img src="../assets/video.svg" style="width:60px; margin-right:15px;" />
          <div style="flex-grow:1;">
            <b>Ready?</b>
            <br>
            <span style="color: #777;">Check that all devices are ready before you press 'play segment'.</span>
          </div>
        </div>
      </div>
    </section>

    <section class="leftside" :class="{playing: current.segment}" v-show="current.status != 0">      
      <div>
        <a href="#" style="display:block; text-decoration:none;" title="Back to session list" @click.prevent="backButtonModal = true">
          <img src="../assets/arrow_l.svg" style="width:16px;height:16px;vertical-align:middle;" /> <span style="vertical-align:middle;">Back to dashboard</span>
        </a>

        <div class="tutorial" v-if="env != 'prod'">          
          <div class="thumbnail" @click="showTutorial = true;"></div>
          <div class="title">
            <span>Tutorial clip</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" title="Click to copy link" @click="tutorialLinkToClipboard">
              <title>Click to copy link</title>
              <path d="M8.633 14.195 6.748 16.08a2 2 0 1 1-2.829-2.827L7.69 9.48a2 2 0 0 1 2.828 0 .667.667 0 0 0 .943-.943 3.333 3.333 0 0 0-4.713 0L2.976 12.31a3.333 3.333 0 1 0 4.714 4.713l1.886-1.885a.667.667 0 0 0-.943-.943z" fill="#00684B"/>
              <path d="M17.023 2.976a3.334 3.334 0 0 0-4.714 0l-2.262 2.262a.667.667 0 0 0 .943.943l2.262-2.262a2 2 0 0 1 2.829 2.828l-4.149 4.148a2 2 0 0 1-2.827 0 .667.667 0 0 0-.943.943 3.333 3.333 0 0 0 4.713 0l4.148-4.148a3.333 3.333 0 0 0 0-4.714z" fill="#00684B"/>
            </svg>
          </div>
          <div v-if="showTutorial" style="padding:50px; position:fixed; left:0;right:0;top:0;bottom:0;z-index:100;background-color: rgba(58, 58, 58, 0.9);">
            <div style="position: relative; width: 100%; max-width: 955px; height: 100%; margin: 0 auto;">
              <iframe :src="`https://player.vimeo.com/video/${tutorialVimeoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Tutorial_Class_V203_Updated"></iframe>
            </div>
            <button title="Close" @click="showTutorial = false;" style="width: 30px; height: 30px; min-width: 30px; color: #cccccc; padding: 0; outline: none; background-color: #000; position: fixed; right: 15px; top: 15px;">
              X
            </button>
          </div>
          <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
        </div>

        <h2 style="margin-bottom:0;margin-top:16px;">Segments</h2>        
      </div>            
      <ul class="tree" v-if="selectedSession && selectedTreeItem">        
        <TreeItem2
          v-for="(item, index) in selectedSessionStructure.list" :key="index"          
          class="treeitem2"
          @select="treeItemSelected"
          :item="item"
          :selectedItem="selectedTreeItem"
          :level="0"
          :contentRoot="contentRoot"
          :metadata="selectedSession.metadata"
          :expandAll="expandAllSegments"
        ></TreeItem2>
      </ul>
      <div style="text-align:center; margin-top:20px;">
        <button class="white" @click="closeSessionModal = true;">
          <img src="../assets/door.svg" style="width: 20px; height:20px; margin-right:10px; margin-bottom:-5px;"> 
          Close session
        </button>
      </div>
    </section>

    <section class="middle" v-show="current.status != 0">
      <div class="segment" v-if="segment">   
        <div class="image">   
          <img :src="contentRoot + (current.segment ? current.segment.icon : selectedTreeItem.icon)" />
        </div>
        <div class="text">
          <span style="color:#585c5e; line-height:1.75; position: relative; display: inline-block;">{{current.segment ? 'Now playing' : 'Segment'}}
            <svg v-if="current.segment" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto; position: absolute; right: -26px; top: 5px;" width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" r="37" stroke-width="7" stroke="#539a55" stroke-dasharray="58.119464091411174 58.119464091411174" fill="none" stroke-linecap="round">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2.564102564102564s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
              </circle>  
              <polygon points="50 15, 100 100, 0 100" fill="#00684B" transform-origin="50 50" transform="rotate(90), scale(0.4), translate(0,-20)"/>
          </svg>
          </span>
          <br>
          <span style="font-size:18px;">{{current.segment ? current.segment.txt1 : selectedTreeItem.txt1}}</span>            
        </div>    
        <div class="conditions" v-if="conditions && !current.segment">
          <span v-if="conditions" style="color:#585c5e; line-height:1.75;">Conditions to play</span>
          <br>
          <div class="control" v-if="conditions && !conditionsWithRequirements">            
            <button ref="conditionsdropdown" :disabled="current.segment" class="insead white autowidth dropdown" onclick="this.toggleAttribute('opened')">
              <template v-if="checkedConditions.length"> 
                <span v-for="condition in checkedConditions" :key="condition.condition" class="letter">{{condition.condition}}<!--. {{condition.txt1}}--></span>
              </template>
              <span v-else>
                Select conditions
              </span>
              <div class="menuitems">
                <label v-for="condition in conditions" :key="condition.condition" :value="condition.condition" v-show="condition.condition != 'summary'">                
                    <input type="checkbox" :id="condition.condition" :value="condition" v-model="checkedConditions">
                    <span> {{condition.condition}}. {{condition.txt1}}</span> 
                </label>            
              </div>
            </button>  
          </div>
          <div class="control" v-if="conditions && conditionsWithRequirements">
            
            <select class="select-css" disabled>
              <option disabled selected>Automatic</option>
            </select>
          </div>
        </div>   
        <div v-if="conditions && current.conditions && current.conditions.length" style="padding-right:20px; padding-left: 50px;">
          <div class="title" style="color:#585c5e; line-height:1.75; position: relative; display: inline-block;">Conditions</div>
          <div>
            <span v-for="condition in current.conditions" :key="condition.condition" class="letter">{{condition.condition}}</span>
          </div>
        </div>        
        <div class="controls">          
            <button class="play" 
              :class="{active: current.status == 1 && current.segment}" 
              :disabled="(current.status == 1 && current.segment) || playResourceDisabled"
              @click="playSegmentModal = true"
              :title="(testUserIds.length == 0 && selectedSession.testMode) ? 'Select at least one test device' : (playResourceDisabled && conditions ? 'Select at least one condition' : '')"
            ></button>   
            <button class="stop" 
              :disabled="[2,3].includes(current.status) || !current.segment || testModeSelector"
              @click="exitSegmentModal = true;"
            ></button>             
        </div>  
        <div style="padding-left:40px;" v-if="current && current.segment">
          <div style="color: #585c5e; line-height:1.75;">Elapsed time</div>          
          <div style="font-size:18px;">{{elapsedTime}}</div>
        </div>
        <!-- <div class="overallprogress" v-if="current.segment">
          <div class="title">Overall completion <span class="help"></span></div>
          <div class="progressbar">
            <span>{{overallProgress}}%</span>
            <div class="progress" v-bind:style="{ width: overallProgress+'%' }"></div>
          </div>
        </div>      -->
      </div>
      <div v-else style="width: 100%; height:70px;"></div>

      

      <div class="timeline" :class="{hasconditions: conditions}">
        <h2>Segment timeline {{(current && current.segment) ? `- ${isNaN(timelineOverallProgress) ? 0 : timelineOverallProgress}%` : ''}}
          <span class="help">
            <div>
              <table>
                <tr>
                  <th colspan="2">Icons</th>
                  <th colspan="2">Bar color</th>
                </tr>
                <tr>
                  <td><img src="../assets/vidio.svg" /></td>
                  <td>Videos</td>
                  <td><div class="color" style="background-color: #585c5e;"></div></td>
                  <td>Nobody here yet</td>
                </tr>
                <tr>
                  <td><img src="../assets/question.svg" /></td>
                  <td>Questions</td>
                  <td><div class="color" style="background-color: #ff8741;"></div></td>
                  <td>Some learners are here</td>
                </tr><tr>
                  <td><img src="../assets/tap.svg" /></td>
                  <td>Interactions</td>
                  <td><div class="color" style="background-color: #4cad82;"></div></td>
                  <td>Everyone has finished</td>
                </tr>
              </table>
            </div>
          </span>
        </h2>
        <div class="chart">
          <div v-for="(item, idx) in timelineItemsWithProgress" 
              :key="'tl' + idx + item.groupId"
              class="item"               
              :class="[{duration: item.duration, inProgress: item.progress > 0, finished: item.progress == 100}, item.type]"
              :style="getTimelineItemStyle(item)"
              :title="getTimelineItemTitle(item)"
              :id="'timelineItem'+item.groupId"
            >
              <video v-if="item.type == 'vid' || (item.type =='textVid' && item.items[0].fname)" :id="'timelineVideo'+item.groupId+segment.id" preload="metadata" :src="contentRoot+encodeURIComponent(item.items[0].fname)" @error="videoLoadingError(item)" @durationchange="setTimelineItemDuration(item)"></video>              
              <div class="icon" :id="'timelineIcon'+item.groupId+segment.id" :key="'timelineIcon'+item.groupId+segment.id">
                <img v-if="item.type == 'vid' || item.type =='textVid'" src="../assets/vidio.svg" />
                <img v-else-if="item.type == 'Q'" src="../assets/question.svg" />
                <img v-else src="../assets/tap.svg" />
              </div>
              <div class="time" style="text-align:right" v-if="item.duration">                
                {{`${String(Math.floor(item.duration/60))/*.padStart(2,'0')*/}:${String(Math.round(item.duration%60)).padStart(2,'0')}`}}     
              </div>
          </div> 
        </div>
      </div>

      <div class="condition-tabs" v-if="conditions">
        <span v-for="condition in conditions" :key="condition.condition" v-show="condition.condition != 'summary'"
          :class="{active: selectedTreeItem == condition}"
          @click="selectedTreeItem = condition">
          {{condition.condition}} - {{condition.txt1}}
        </span> 
      </div>

      <div class="analytics" :class="{hasconditions: conditions, expanded: expandedView}">
        <div v-if="(!questions || questions.length == 0) && (!heatmapVideoItems || heatmapVideoItems.length == 0) && (!hotspots || hotspots.length == 0) && (!shops || !shops.length) && (!retailshops || !retailshops.length)" 
          style="display:flex;flex-direction:column;justify-content: center;align-items: center;gap: 32px;height: 100%;">
          <div style="font-size:20px;color:#a2a2a2;">There are no interactions in this segment</div>
          <img src="../assets/no_questions.svg" style="width:128px;height:128px;" />
        </div>
        <div v-else>
          <div class="ribbon">
            <div class="tabs">
              <span class="title" :class="{active: analyticsTab == 'questions'}" v-if="questions && questions.length" @click="analyticsTab = 'questions'">Questions results</span> 
              <span class="title" :class="{active: analyticsTab == 'heatmap'}" v-if="heatmapVideoItems && heatmapVideoItems.length" @click="analyticsTab = 'heatmap'">Behavior</span>
              <span class="title" :class="{active: analyticsTab == 'hotspots'}" v-if="(hotspots && hotspots.length) || (shops && shops.length) || (retailshops && retailshops.length)" @click="analyticsTab = 'hotspots'">Interactions</span>
              <ToggleButton style="margin-left:10px;" v-if="analyticsTab == 'hotspots' && hotspots && hotspots.length" v-model="hotspotBreakdown" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" />
              <span v-if="analyticsTab == 'hotspots' && hotspots && hotspots.length" style="font-size:14px; line-height: 20px; cursor: unset; color: #777;">Breakdown</span>
            </div>
            <div class="buttons" v-show="segment">
              <!-- <button class="text white" :disabled="archiveDisabled" @click="archiveResultsModal = true">
                <img src="../assets/folder-download.svg" style="width:20px; height:19px; vertical-align:middle; margin-right:6px;" />
                <span style="vertical-align:middle;"> Archive</span>
              </button>    
              <select class="select-css timerangedropdown" style="width: auto; font-size:14px;" v-model="resultsSerial" @change="resultsGroupSelected">                    
                <option v-for="group in resultsGroups" v-bind:value="group.serial" :key="group.serial" :selected="group.serial == resultsSerial">
                  {{ group.serial == null ? 'Current' : group.serial }}{{group.from || group.to ? ':' : ''}} {{ group.from ? new Date(group.from).toLocaleDateString() : ''}} {{group.from && group.to ? '-' : ''}} {{group.to ? new Date(group.to).toLocaleDateString() : ''}} 
                </option>
                <option value="-1" v-if="resultsGroups.length > 1">All</option>          
              </select>    -->
              <span class="spinner relative" style="left: unset;" v-if="loading" />
              <button class="text white" v-show="analyticsTab == 'questions' || (this.analyticsTab == 'hotspots' && ((shops && shops.length) || (retailshops && retailshops.length)))" :disabled="loading || !results || results.length == 0" @click="resultsToClipboard">
                <img src="../assets/copy.svg" style="width:20px; height:20px; vertical-align:middle; margin-right:6px;" />
                <span style="vertical-align:middle;">Copy {{this.analyticsTab == 'hotspots' ? 'shopping' : ''}} results</span>
              </button> 
              <button class="text white" v-show="analyticsTab == 'questions'" :disabled="!results || results.length == 0" @click="exportResultsModal = true">
                <img src="../assets/folder-download.svg" style="width:20px; height:20px; vertical-align:middle; margin-right:6px;" />
                <span style="vertical-align:middle;">Export CSV</span>
              </button>  
              <button class="icon tooltip" @click="expandedView = !expandedView">
                <img src="../assets/expand.svg" style="width:20px; height:20px; vertical-align:middle;" />
                <span class="tooltiptext">{{expandedView ? 'Collapse' : 'Expand'}} view</span>
              </button>             
            </div>
          </div>
          <div class="data">
            <Results2 
              ref="results"
              v-show="analyticsTab == 'questions'"
              v-bind:conditions="conditions" 
              v-bind:questions="questions"
              v-bind:results="results" 
              v-bind:contentRoot="contentRoot" 
              :segmentId="(segment && conditions && selectedCondition) ? selectedCondition.id : (segment ? segment.id : undefined)"
            />

            <ShoppingRetail 
              ref="retailshopping"    
              v-show="analyticsTab == 'hotspots'" v-if="selectedSession && retailshops && retailshops.length"          
              v-bind:shops="retailshops"
              v-bind:results="results" 
              v-bind:contentRoot="contentRoot" 
              :segmentId="(segment && conditions && selectedCondition) ? selectedCondition.id : (segment ? segment.id : undefined)"
            />

            <Shopping 
              ref="shopping"    
              v-show="analyticsTab == 'hotspots'" v-if="selectedSession && shops && shops.length"          
              v-bind:shops="shops"
              v-bind:results="results" 
              v-bind:contentRoot="contentRoot" 
              :segmentId="(segment && conditions && selectedCondition) ? selectedCondition.id : (segment ? segment.id : undefined)"
            />            
            
            <Hotspots2 ref="hotspots" v-show="analyticsTab == 'hotspots'" v-if="selectedSession && hotspots && hotspots.length"
              :hotspots="hotspots"
              :results="results"
              :segmentId="(segment && conditions && selectedCondition) ? selectedCondition.id : (segment ? segment.id : undefined)"
              :labelName="segment.hotspotsLabelName"
              :hotspotBreakdown="hotspotBreakdown"
            />  

            <Heatmap2 ref="heatmap" v-show="analyticsTab == 'heatmap'" v-if="selectedSession && heatmapVideoItems && heatmapVideoItems.length" 
              :sessionId="selectedSession.id" 
              :segmentId="(segment && conditions && selectedCondition) ? selectedCondition.id : (segment ? segment.id : undefined)"
              :videos="heatmapVideoItems"
              :contentRoot="contentRoot"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="rightside">
      <!-- <img v-if="selectedSession && (selectedSession.status != 0) && (selectedSession.status != 4)" 
        title="Re-invite devices to this session" 
        src="../assets/user_a.svg" 
        style="width:24px; height:24px; position: absolute; right:22px; cursor: pointer;" 
        @click="reinviteUsers"
      /> -->
      <h2>Learners</h2>
      <div class="devicebar" :class="{readyFilter: deviceFilter == 'readyFilter', errorFilter: deviceFilter == 'errorFilter'}">
        <!-- <span class="spinner relative" v-if="loading" />
        <div class="counter errordetails" v-if="devicesWithErrors.length">{{devicesWithErrors.length}} devices with errors 
          <a @click="deviceErrorsModal = true">[DETAILS]</a>          
        </div>           -->
        <div class="counter">
          <span class="filter" title="Show all devices" :class="{inactive: deviceFilter}" @click="deviceFilter = null">{{users.length}} All</span>
          <span class="filter" title="Show only ready devices" :class="{inactive: deviceFilter && deviceFilter != 'readyFilter'}" @click="deviceFilter = 'readyFilter'"><span style="color:#4cad82; font-weight:bold; margin-left:14px;">{{devicesReady().length}}</span> Ready</span>
          <span class="filter" title="Show only devices with problems" :class="{inactive: deviceFilter && deviceFilter != 'errorFilter'}" @click="deviceFilter = 'errorFilter'"><span style="color:#f95151; font-weight:bold; margin-left:14px;">{{devicesWithErrors.length}}</span> Error</span>          
          <!-- <div @click="$router.push(`/sessions/${selectedSession.id}/users`)" title="Edit assigned users">👥</div> -->
        </div>  
        <div v-if="selectedSession && selectedSession.testMode" style="height:50px; padding-right:12px;">
          <div v-if="testModeSelector" style="display:flex; gap: 10px;">
            <button class="white" style="min-width: 80px; flex-grow:1;" @click="testModeSelector = false;">Cancel</button>
            <button style="min-width: 80px; flex-grow:1;" @click="setTestModeUsers">Ok</button>
          </div>
          <button v-else @click="testModeSelector = true;" :disabled="current.segment" :title="current.segment ? 'Cannot change test devices while segment is playing' : ''" style="width: 100%;">Choose test devices</button>
        </div>
        <button class="white" style="height:30px; width: calc(100% - 12px);" @click="reinviteUsers" v-if="selectedSession && (selectedSession.status != 0) && (selectedSession.status != 4)">
          <img src="../assets/reinvite.svg" style="width: 20px; height:20px; margin-right:10px; margin-bottom:-5px;"> 
          Re-invite learners
        </button>
        <div :style="`overflow-y: auto; margin-top:15px; max-height: calc(100lvh - var(--size-header) - ${selectedSession && selectedSession.testMode ? '200' : '150'}px);`">  
          <div v-for="user in orderedUsers" class="devicecard tooltip" :key="user.id" 
            :class="{
              offline: !user.device || !user.device.timestamp || ((ticker-user.device.timestamp) > $constants.deviceOfflineTimeout), 
              testMode: selectedSession && selectedSession.testMode && testUserIds.includes(user.id), 
              unlinked: !user.device, onhead: user.device && user.device.onhead, 
              technical: user.lastName == 'technical', 
              hasError: user.device && user.device.hasError
            }"
            @click="fileDetails(user.device)"
          >
            <div style="display: flex">
              <div class="condition" v-if="conditions && user.condition && selectedSessionIdMap.get(Number(user.condition))">{{ selectedSessionIdMap.get(Number(user.condition)).condition}}</div>
              <div class="name">{{user.firstName}}</div>
              <div v-if="testModeSelector" style="height:16px;">
                <input type="checkbox" style="height:unset;" @click.stop="" v-model="testUserIds" :value="user.id" :disabled="testUserIds.length > 2 && !testUserIds.includes(user.id)" />
              </div>
              <div v-else class="battery" :class="{red: user.device && user.device.battery && user.device.battery <= 20}">
                {{(user.device && user.device.battery) ? user.device.battery : '--'}}% 
                <div class="percentage" v-bind:style="{ transform: 'scaleX('+ ((user.device && user.device.battery) ? user.device.battery : 0)/100.0 + ')' }"></div>
              </div>
            </div>
            <!-- <div class="tooltiptext left" v-if="user.device && user.device.fileIntegrityList && user.currentSessionId == selectedSession.id">          
              <table>
                <tr>
                  <th>File</th>
                  <th>Reason</th>
                </tr>
                <tr v-for="file in fileIntegrityDetails(user.device.fileIntegrityList)" :key="file.key">
                  <td>{{file.key}}</td>
                  <td>{{file.reason}}</td>
                </tr>
              </table>          
            </div> -->
            <div class="error" v-if="user.device && user.device.hasError">
              Content error
            </div>
          </div>        
        </div>
      </div>
    </section>

    <Snackbar ref="snackbar" /> 

    <Header2 :banner="false" :class="{green: current.status == 3 || current.status == 2, purple: selectedSession && selectedSession.practice, testMode: selectedSession && selectedSession.testMode}">
      <template v-if="selectedSession">
        <div class="header-controls">
          <div>
            <div style="font-size:20px; font-weight: bold;">{{ selectedSession.name }}</div>
            <div style="white-space: pre;"><span>{{ selectedSession.id }} - {{ selectedSession.startDate ? formatDate(new Date(selectedSession.startDate), $store.getters.timeZone) : 'No date' }}{{selectedSession && selectedSession.testMode ? ' - Test Mode' : ''}}</span></div>
            
            <!-- <button class="insead white autowidth tooltip">Finish session<span class="tooltiptext">This will conclude the session for you and all participants</span></button> -->
            
          </div>
          <div v-if="current.status && current.status == 2" style="display:flex; justify-content:space-around;">
            <!-- <div style="margin-right:10px;">
              <div style="color: #585c5e; margin-top:5px;">Elapsed time</div>
              <div style="color:white;">{{elapsedTime}}</div>
            </div> -->
            <div>
              <div style="font-size: 20px; font-weight:bold;">Paused</div>
              <div style="color: white;">Session is paused</div>
            </div>
            <button class="white" @click="resumeSession" style="margin-top:2px;">Resume</button>
          </div>
          <div v-else-if="current.status && current.status == 3" style="display:flex; justify-content:space-around;">
            <div style="margin-right:10px;">
              <div style="color: #585c5e; margin-top:5px;">Elapsed time</div>
              <div style="color:white;">{{elapsedTime}}</div>
            </div>
            <div>
              <div style="font-size: 20px; font-weight:bold;">Self Paced Mode</div>
              <div style="color: white;">Your students can view all session content freely</div>
            </div>
            <button class="white" @click="selfPacedOffModal = true;" style="margin-top:2px;">Turn off</button>
          </div>
          <!-- <div v-else-if="current.status && current.status != 0">
            <div style="color: #585c5e;">Elapsed time</div>
            <div style="font-size:18px;">{{elapsedTime}}</div>
          </div> -->
          <div>
            <button v-if="current.status && current.status != 0 && current.status != 4" class="icon tooltip pause" @click="pauseSessionModal = true;" :class="{selected: current.status == 2}" :disabled="current.status == 2"><span class="tooltiptext" v-if="current.status != 2">Send a message to everyone</span></button>
            <button v-if="current.status && current.status != 0 && current.status != 4" class="icon tooltip selfpace" @click="selfPacedOnModal = true;" :class="{selected: current.status == 3}" :disabled="current.status == 3"><span class="tooltiptext" v-if="current.status != 3">When self pacing is on, participants can browse session resources freely</span></button>                  
          </div>
        </div>
      </template>
    </Header2>

    <Modal2 v-if="playSegmentModal" @close="playSegmentModal = false;" class="playsegmentmodal center">      
      <h3 slot="header"><img src="../assets/vrglasses.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Playing content</h3>
      <div slot="body">
        The segment will play on all session devices.
        <br>
        <br>
        Make sure everyone is READY!        
      </div>
      <div slot="footer">
        <button class="autowidth right" @click="playSegmentModal = false; playSegment()">Play the Segment</button>          
        <button class="white autowidth right" @click="playSegmentModal = false">Cancel</button>                        
      </div>
    </Modal2> 
    <Modal2 v-if="pauseSessionModal" @close="pauseSessionModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/broadcast.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" />Broadcast message</h3>
      <div slot="body">
        Broadcasting a message will pause all devices connected to this session!          
        <div style="margin-top: 30px;">
          Message to participants
          <div style="float: right; color: #9999AA; font-size:14px;">{{pauseSessionMessage.length}}/144 characters</div>
        </div>            
        <textarea style="margin-top: 6px; height:100px;" v-model.trim="pauseSessionMessage" maxlength="144" placeholder="Type message to be transmitted to all participants"></textarea>
      </div>
      <div slot="footer">  
        <button class="right" :disabled="!pauseSessionMessage.length" :class="{disabled: !pauseSessionMessage.length}" @click="pauseSessionModal = false; pauseSession()">Broadcast message</button>               
        <button class="white right" @click="pauseSessionModal = false">Cancel</button> 
      </div>
    </Modal2>
    <Modal2 v-if="selfPacedOnModal" @close="selfPacedOnModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/appointment.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" />Self Paced Mode</h3>
      <div slot="body">
        Allow your students to explore the session content freely at their own pace.
      </div>
      <div slot="footer">                
        
        <button class="right" @click="selfPacedOnModal = false; selfPacedOn()">Turn self pacing on</button>  
        <button class="white right" @click="selfPacedOnModal = false">Cancel</button>             
      </div>
    </Modal2>
    <Modal2 v-if="selfPacedOffModal" @close="selfPacedOffModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/appointment.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" />Turn Self Pacing Off</h3>
      <div slot="body">
        Students will be taken back to the session waiting screen, interrupting any ongoing playback. They will not be able to browse content freely anymore.
      </div>
      <div slot="footer">                        
        <button class="right" @click="selfPacedOffModal = false; selfPacedOff()">Turn self pacing off</button>  
        <button class="white right" @click="selfPacedOffModal = false">Cancel</button>              
      </div>
    </Modal2>
    <Modal2 v-if="exitSegmentModal" @close="exitSegmentModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/stop1.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Stop segment</h3>
      <div slot="body">
        This will stop playback for everyone regardless their progress, and bring them back to the session screen.
      </div>
      <div slot="footer">       
        <button class="right" @click="exitSegmentModal = false; exitSegment()">Yes, end playback</button>   
        <button class="right white" @click="exitSegmentModal = false">Cancel</button>            
      </div>
    </Modal2> 
    <Modal2 v-if="startSessionModal" @close="startSessionModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/play1.svg" style="width: 16px; margin-right:12px; vertical-align:middle;" />Launch session {{this.testMode ? 'in test mode' : ''}}</h3>
      <div slot="body">
        <div v-if="usersInDifferentSession && usersInDifferentSession.userCount > 0">
          {{usersInDifferentSession.userCount}} devices assigned to this session are currently connected to another active session:          
          <div v-for="s in usersInDifferentSession.otherSessions" :key="s.id" style="padding-top: 6px;">
            <b>"{{s.id}} - {{s.name}}"</b>
          </div>
          <div style="padding-top: 6px;">Launching the session now will send a message to those devices asking if they want to switch to this session.</div>
        </div>
        <br>
        <div v-if="devicesWithErrors && devicesWithErrors.length > 0">
          {{devicesWithErrors.length}} devices have content errors.
        </div>
        <br>
        <div v-if="usersWithoutDevices && usersWithoutDevices.length > 0">
          {{usersWithoutDevices.length}} users don't have a device linked.
        </div>  
        <div v-if="!users || !users.length">
          No devices are assigned to this session.
        </div>      
      </div>
      <div slot="footer">   
        <span class="spinner" style="left: unset;" v-if="loading" />    
        <button class="right" @click="startSessionModal = false; startSession()" :disabled="loading">Start session</button>   
        <button class="right white" @click="startSessionModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>
    <Modal2 v-if="closeSessionModal" @close="closeSessionModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/close1.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Close session</h3>
      <div slot="body">
        Closing the session in the middle of the run will stop the session.
        <br>
        <br>
        The results will be saved up to this point.
      </div>
      <div slot="footer">       
        <button class="right" @click="closeSessionModal = false; closeSession()">Close session</button>   
        <button class="right white" @click="closeSessionModal = false">Cancel</button>            
      </div>
    </Modal2>
    <Modal2 v-if="exportResultsModal" @close="exportResultsModal = false;" class="center">      
      <h3 slot="header">Export questions results</h3>
      <div slot="body">            
        If you have collected user metadata during the session to distinguish users, then this metadata key will be used as the external user identifier (the default used in most experiences is called "pnumber").
        <br>
        <br>
        Otherwise, questions results export is grouped by User ID by default (in guest mode, without real-life user accounts this is the VR device itself). 
        <!-- <label>Group by</label>
        <br style="clear:both;">
        <input type="radio" v-model="externalUserId" :value="undefined" /> default UserId
        <br>
        <input type="radio" v-model="externalUserId" :value="'pnumber'" /> 'pnumber' user metadata -->
      </div>
      <div slot="footer"> 
        <button class="right" @click="exportFlattenedResults(); exportResultsModal = false;">Export</button>  
        <button class="right white" @click="exportResultsModal = false">Cancel</button>                            
      </div>
    </Modal2> 
    <Modal2 v-if="archiveResultsModal" @close="archiveResultsModal = false;" class="center">      
      <h3 slot="header">Archive current results</h3>
      <div slot="body">
        When archiving, the current session results (latest set of answers and heatmap data) will be reset, but not deleted, and still available as historical data.
      </div>
      <div slot="footer"> 
        <button class="right" @click="archiveResultsModal = false; archiveResults()">Archive current results</button>  
        <button class="right white" @click="archiveResultsModal = false">Cancel</button>                            
      </div>
    </Modal2> 
    <Modal2 v-if="deviceErrorsModal" @close="deviceErrorsModal = false;">      
      <h3 slot="header">Content issues for device: {{tempDevice.name}}</h3>
      <div slot="body">
        <table v-if="tempDevice.brokenFiles.length > 0">
            <tr style="text-align:left;">
              <th>File</th>
              <th>Server Date</th>
              <th>Device Date</th>
              <th>Reason</th>
            </tr>                      
            <tr v-for="file in tempDevice.brokenFiles" :key="file.key">                
              <td>{{file.key}}</td>
              <td>{{file.serverLastModified}}</td>
              <td>{{file.lastModified ? file.lastModified : ''}}</td>
              <td>{{file.reason}}</td>
            </tr>         
        </table> 
        <div v-else>
          Today is a good day, all content is fine on this device 
          <br>
          (• ◡•)
        </div>
      </div>
      <div slot="footer">               
        <button class="insead white" @click="deviceErrorsModal = false">Close</button>              
        <!-- <button class="insead" @click="exportDeviceErrors">Download CSV</button> -->
      </div>
    </Modal2> 
    <Modal2 v-if="backButtonModal" @close="backButtonModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/sessions.svg" style="width: 32px; margin-right:12px; vertical-align:middle;" />Keep Your Session Active or Close Now?</h3>
      <div slot="body">
        The session will remain active even after clicking "Back to Dashboard".
        <br>
        <br>
        Would you like to close the session now, or keep it open?
      </div>
      <div slot="footer">       
        <button class="right" @click="backButtonModal = false; $router.push('/upcoming')">Keep active &amp; leave</button>            
        <button class="right white" @click="backButtonModal = false; closeSession()">Close Session</button>   
      </div>
    </Modal2> 
  </main>
</template>

<script>
import { HubConnectionBuilder } from '@microsoft/signalr'
import axios from 'axios'
//import {Roles} from '@/roles.js'
import TreeItem2 from '@/components/TreeItem2.vue'
import _ from 'lodash'
import Snackbar from '@/components/Snackbar.vue'
import Header2 from '@/components/Header2.vue'
import Results2 from '@/components/Results2.vue'
import Modal2 from '@/components/Modal2.vue'
import Hotspots2 from '@/components/Hotspots2.vue'
import Shopping from '@/components/Shopping.vue'
import ShoppingRetail from '@/components/ShoppingRetail.vue'
import Heatmap2 from '@/components/Heatmap2.vue'
import { formatDate, toTimeZone } from '@/utils.js'

export default {
  name: 'Dashboard2',
  components: {
    TreeItem2,
    Snackbar,
    Header2,
    Results2,
    Modal2,
    Hotspots2,
    Shopping,
    ShoppingRetail,
    Heatmap2
  },
  data: function(){
    return {          
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,
      backblazeBlobRoot: process.env.VUE_APP_PUBLIC_BLOB_BACKBLAZE,
      connection: undefined,
      users: [],
      results: [],   
      resultsGroups: [],
      resultsSerial: null, 
      ticker: Date.now(),
      secondTicker: Date.now(),
      selectedSession: undefined,
      selectedSessionIdMap: new Map(),
      loading: false,
      selectedTreeItem: undefined,
      checkedConditions: [],
      playSegmentModal: false,
      exitSegmentModal: false,
      pauseSessionModal: false,
      archiveResultsModal: false,
      exportResultsModal: false,
      pauseSessionMessage: '',
      selfPacedOnModal: false,
      selfPacedOffModal: false,
      closeSessionModal: false,
      startSessionModal: false,
      backButtonModal: false,
      current: {
        status: undefined,
        segment: undefined,
        conditions: undefined,
        allocation: undefined,
        segmentStarted: undefined
      },
      expandAllSegments: true,
      elapsedTime: '00:00:00',
      reconnecting: false,
      dashboardUsers: [],
      analyticsTab: 'questions',
      expandedView: false,
      hotspotBreakdown: true,
      tempDevice: undefined,
      deviceErrorsModal: false,
      deviceFilter: undefined,
      testModeSelector: false,
      testUserIds: [],
      s3files: [],      
      timelineItems: [],
      testMode: false,
      usersInDifferentSession: undefined,
      tutorialVimeoId: '884375247',
      showTutorial: false
    }
  },
  watch: {
    // whenever selectedTreeItem changes, this function will run
    selectedTreeItem: function (newItem, oldItem) {

      if((newItem.parent?.id && oldItem.parent?.id && newItem.parent.id != oldItem.parent.id) || !(newItem.parent?.id))
        this.analyticsTab = 'questions'

      // fine tune default analytics tab shown on load
      let tabs = new Map([
        ['questions', this.questions && this.questions.length],
        ['heatmap', this.heatmapVideoItems && this.heatmapVideoItems.length],
        ['hotspots', ((this.hotspots && this.hotspots.length) || (this.shops && this.shops.length) || (this.retailshops && this.retailshops.length))]
      ])      

      if(!tabs.get(this.analyticsTab)){
        tabs.delete(this.analyticsTab)
        for (const [tab, available] of tabs) {
          if(available) {
            this.analyticsTab = tab
            return
          }
        }
        this.analyticsTab = 'questions' // default
      }
    },
    segment(newSegment, oldSegment){
      if(newSegment.id != oldSegment?.id)
        this.generateTimelineItems()
    },
    flatItemlist: function(){
      
    },
    // "$route.params": async function(newParams, oldParams) {      
    //   if(newParams.sessionId == oldParams.sessionId)
    //     this.selectedTreeItem = this.selectedSessionIdMap.get(Number(this.$route.params.treeId ))
    //   else{
    //     // await this.getCurrentSession()
    //     this.selectedSession = this.sessions.find(s => s.id == newParams.sessionId)
    //     await this.handleSessionSelected()
    //   }
    // }  
    expandedView(){
      //setTimeout(() => this.$refs?.heatmap.resize(true), 500)
      setTimeout(() => window.dispatchEvent(new Event('resize')), 500)
    },
    analyticsTab(){
      //setTimeout(() => this.$refs?.heatmap.resize(true), 500)
      setTimeout(() => window.dispatchEvent(new Event('resize')), 500)
    }      
  },
  computed: { 
    env: function(){
      return process.env.VUE_APP_ENV
    },
    contentRoot(){
      return this.selectedSession?.webRoot 
      ? (this.backblazeBlobRoot + (this.selectedSession?.webRoot ?? ''))
      : (this.blobRoot + (this.selectedSessionStructure?.webContentRootPath ?? ''))
    },
    selectedSessionStructure(){      
      if(!this.selectedSession)
        return undefined
      let tmp = JSON.parse((this.selectedSession.custom || !this.selectedSession.contentStructure /* legacy fallback */) 
        ? (this.selectedSession.structure /* should be always the snapshot from creation, experience structure just as fallback for now */ ?? this.selectedSession.experience.structure) 
        : this.selectedSession.contentStructure)
      this.selectedSessionIdMap.clear()   
      this.setTreeParents(tmp)      
      return tmp
    }, 
    playResourceDisabled(){
      return (this.conditions && !this.conditionsWithRequirements && !this.checkedConditions.length) || (this.current.segment?.id == this.segment?.id) || (this.selectedSession.testMode && this.testUserIds.length == 0) || (this.testModeSelector)
    },
    segment(){
      if(!this.selectedTreeItem || this.selectedTreeItem.type == 'list')
        return undefined
      return (this.selectedTreeItem.type == 'seg' || this.selectedTreeItem.type == 'conditional')
        ? (this.selectedTreeItem.parent && this.selectedTreeItem.parent.type == 'conditional')
          ? this.selectedTreeItem.parent
          : this.selectedTreeItem
        : undefined
    },       
    questions(){
      if(!this.segment)
        return undefined     

      let items = this.flatItemlist.filter(i => i.Q)

      // same question can be present in each condition within same segment, but we just need to display one of course
      // const arrayUniqueByKey = [...new Map(items.map(item =>[item['id'], item])).values()]
      
      // handle question filtering for condition in Results component, otherwise if same question is inserted into multiple conditions, it would be missing     

      let regex = /A[1-9]+/
      //items/*arrayUniqueByKey*/.forEach(question => 
      for (let index = 0; index < items.length; index++) {
        let question = items[index];       
      
        question.options = []
        if(question.type == 'interview')
        {
          //if(question.mode == undefined)
          this.$set(question, 'mode', 'byIv') // default for interviews
          question.interviewees.forEach(iv => this.$set(iv,'expanded', false))
          
          // mapping of interview with 1 interviewee -> multiple choice question
          if(question.interviewees?.length == 1){
            let singlePersonInterview = {
              id: question.interviewees[0].id,
              Q: question.interviewees[0].Q,
              Qdashboard: question.interviewees[0].Qdashboard,
              type: 'interviewSinglePerson',
              condition: question.condition,
              segmentId: question.segmentId,
              options: question.interviewees[0].questions.map(q => ({id: q.optionId, text: q.title ?? q.question, image: q.icon ?? q.image}))
            }
            items.splice(index,1,singlePersonInterview)
          }
          else if(question.interviewees?.length > 1 && !question.chooseQuestions && !question.followupQuestions && question.interviewees.every(iv => iv.questions.length == 1)){
            let interviewIntroductions = {
              id: question.id,
              Q: question.Q,
              segmentId: question.segmentId,
              Qdashboard: question.Qdashboard,
              type: 'interviewIntroductions',
              condition: question.condition,
              options: question.interviewees.map(iv => ({id: iv.id, text: iv.text ?? iv.Q, image: iv.icon ?? iv.image}))
            }
            items.splice(index,1,interviewIntroductions)
          }
          else if(question.interviewees?.length > 1){
            question.interviewees.forEach(interviewee => {
              question.options.push(({id: interviewee.id, text: interviewee.text, image: interviewee.icon}))            
            })
          }          
        }
        else if(!question.items){
          for (const property in question) {
            //console.log(`${property}: ${object[property]}`);
            if(regex.test(property) && question[property])
              question.options.push(({id: parseInt(property.substring(1)), text: question[property]}))
          }
          // if(!question.options.length && question.type == 'rating')
          //   [5,4,3,2,1].forEach(x => question.options.push(({id: x, text: `${(x).toString()} star${x > 1 ? 's' : ''}`})))
        }
        else{
          question.items.forEach(option => {
            question.options.push(({id: option.optionId, text: option.text, textDashboard: option.textDashboard, image: option.image}))
          });
        }

        if(question.type == 'likert' && !question.descriptors)
          question.descriptors = ["Strongly disagree","Disagree","Neutral","Agree","Strongly agree"]
      }
      //);

      return items //arrayUniqueByKey
    },
    flatItemlist(){
      if(!this.segment)
        return []
      let items = (this.segment.list && this.segment.list.length)
        ? this.segment.list
            .map(seg => seg.segJson.items.map(i =>  ({ ...i, condition: seg.condition, segmentId: seg.id }))) // store to which condition the item belongs to
            .flat()
        : this.segment.segJson.items.map(i =>  ({ ...i, segmentId: this.segment.id }))
        return items
    },
    hotspots(){      
      let hotspots = this.flatItemlist.filter(i => i.type == 'hotspots')
      return hotspots
    },
    shops(){      
      let shops = this.flatItemlist.filter(i => i.type == 'shopping' && !i.shoppingGroup)
      return shops
    },    
    retailshops(){      
      let shops = this.flatItemlist.filter(i => i.type == 'shopping' && i.shoppingGroup)
      return shops
    },
    conditions(){
      if(!this.selectedTreeItem)
        return undefined
      let tmp =  (this.selectedTreeItem.type == 'conditional' && this.selectedTreeItem.list && this.selectedTreeItem.list.length) 
        ? this.selectedTreeItem.list 
        : (this.selectedTreeItem.parent && this.selectedTreeItem.parent.type && this.selectedTreeItem.parent.type == 'conditional' && this.selectedTreeItem.parent.list && this.selectedTreeItem.parent.list.length
          ? this.selectedTreeItem.parent.list
          : undefined)
      if(tmp){
        tmp[0].parent.condition = 'summary'
        return [tmp[0].parent, ...tmp]
      }
      return undefined
    },
    conditionsWithRequirements(){
      let tmp = this.conditions?.filter(c => c.requirements)
      if(tmp && tmp.length) 
        return tmp
      return null
    },
    selectedCondition(){
      return this.conditions?.find(c => c == this.selectedTreeItem)
    },
    heatmapVideoItems(){
      if(this.conditions && this.segment?.list?.length) 
        return this.segment.list.find(i => i.id == this.selectedCondition?.id)?.segJson?.items?.filter(i => i.type == 'vid' && i.heatmap)
      else
        return this.selectedTreeItem?.segJson?.items?.filter(i => i.type == 'vid' && i.heatmap)
    },
    devicesWithErrors(){
      return this.users.filter(u => u.device?.hasError) 
    },
    orderedUsers(){
      if(this.ticker < 0) // force update computed on ticker
        console.log("");
      return _.orderBy([...this.users], [
          (user) => { return user.device && user.device.timestamp && (this.ticker-user.device.timestamp<this.$constants.deviceOfflineTimeout) },
          (user) => { return user.device && user.device.hasError}
        ], ['asc','desc'])
    },    
    usersWithoutDevices(){
      return this.users.filter(u => !u.device)
    },
    overallProgress(){
      let dr = this.devicesReady(true)
      if (dr.length == 0) 
        return 0
      let tmp = dr.reduce((accumulator, user) => accumulator + (user.progress ?? 0), 0) / dr.length
      return +parseFloat(tmp).toFixed( 2 )
    },
    timelineOverallProgress(){
      return Math.round(_.sumBy(this.timelineItemsWithProgress, 'progress') / this.timelineItemsWithProgress.length )
    },
    timelineItemsWithProgress(){      
      let dr = this.devicesReady(true)
      return this.timelineItems.map(item => {
        let maxProgress = 0
        let minProgress = 100 
        let usersCompleted = 0
                             
        if(this.current?.segment){
          this.current?.conditions?.forEach(playingCondition => {
            let conditionUsers = dr.filter(u => u.condition == playingCondition.id)              
            let conditionProgress = conditionUsers.reduce((accumulator, user) => {   
              let conditionItems = item.items.filter(i => i.segmentId == playingCondition.id)  
              // if all users have all items 100% from a timeline group -> finished
              if(item.mode == 'all'){
                let tmp = (conditionItems.reduce((acc, i) => {            
                  //console.log(`accumulator: ${accumulator}, user: ${user.id}, acc: ${acc}, itemId: ${i.id}, userMetadata: ${user.metadata[`cp_${i.id}`]}`)
                  const userProgress = Number(user.metadata[`cp_${i.id}`] ?? 0)
                  minProgress = Math.min(minProgress, userProgress)
                  maxProgress = Math.max(maxProgress, userProgress)
                  return acc + userProgress
                }, 0) / conditionItems.length)
                if(tmp == 100)
                  usersCompleted++
                return accumulator + tmp
              }
              // if each user has at least one item 100% from a timeline group -> finished
              else{
                const userProgress = _.max(conditionItems.map(i => Number(user.metadata[`cp_${i.id}`] ?? 0)))
                minProgress = Math.min(minProgress, userProgress)
                maxProgress = Math.max(maxProgress, userProgress)
                if(userProgress == 100)
                  usersCompleted++
                return accumulator + userProgress
              }

            }, 0) / conditionUsers.length
            // Note: 
            // this is used only in the current iteration of the loop, it does not represent overall condition progress, rather the progress of the item in each condition
            // and if for a condition there are no ready users (that are considered in calculation), then conditionUsers.length will be 0 -> 0/0 -> NaN -> we then exclude that condition
            playingCondition.progress = conditionProgress  
          })
          item.progress = this.current?.conditions ? this.current.conditions.filter(c => !isNaN(c.progress)).reduce((acc, condition) => {return acc + condition.progress}, 0) / this.current.conditions.filter(c => !isNaN(c.progress)).length : null
          item.minProgress = minProgress
          item.maxProgress = maxProgress        
          item.usersCompleted = usersCompleted
          item.usersTotal = dr.length
        }
        else{
          this.current?.conditions?.forEach(cond => cond.progress = 0)
          item.progress = 0
          item.minProgress = 0
          item.maxProgress = 0       
          item.usersCompleted = 0
          item.usersTotal = 0
        }

        return item
      })
    },
    pathPrefixes(){
      return { 
        webContentRootPath: this.selectedSession?.webRoot ?? this.selectedSessionStructure.webContentRootPath,
        deviceRootPath: '/VRData/' + this.selectedSessionStructure.menuFile.split('/')[0] + '/'
      }
    },
  },
  methods: {  
    tutorialLinkToClipboard(){
      navigator.clipboard.writeText(`https://vimeo.com/${this.tutorialVimeoId}`)
      this.$refs.snackbar.show('Tutorial video URL copied to clipboard')
    },
    fileDetails(device){
      if(!device || !device.hasError) // cannot show anything for unlinked devices
        return
      this.tempDevice = {...device}
      let brokenFiles = []
      let deviceFileList = this.tempDevice.fileList?.filter(f => f.key.startsWith(this.pathPrefixes.deviceRootPath))
      this.selectedSession.fileList?.forEach(sessionFile =>{
          let fileKey = sessionFile.key.substring(this.pathPrefixes.webContentRootPath.length)          
          let deviceFile = deviceFileList?.find(f => f.key == `${this.pathPrefixes.deviceRootPath}${fileKey}`)
          if(!deviceFile || deviceFile.size != sessionFile.size)
          {
            brokenFiles.push({
              key: fileKey,
              size: deviceFile?.size,
              serverLastModified: sessionFile.lastModified,
              lastModified: deviceFile?.lastModified,
              reason: !deviceFile 
                ? 'missing'
                : `${deviceFile.size} instead of ${sessionFile.size} bytes`
            })
          }  
        })
      this.$set(this.tempDevice, 'brokenFiles', brokenFiles) 
      this.deviceErrorsModal = true
    },
    async resultsToClipboard(){
      this.loading = true
      if(this.analyticsTab == 'questions')
        await this.$refs.results.exportcanvas()
      else if(this.analyticsTab == 'hotspots')
        await this.$refs.shopping.exportcanvas()
      this.loading = false
    },
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    generateTimelineItemsLinear(){
      const timelineTypes = ['Q','vid', 'hotspots','interview','inventory','credit','rating','likert']
      let merged = []
      let idx = 0
      this.flatItemlist.forEach(function(item, pos, arr){
        // consecutive questions are displayed as one timeline item (but we still need to know how many questions are merged into one "icon" on the UI)
        // TODO decide whether "Q, pic, Q" should also count as consecutive questions (so whether we need to filter first the flatItemlist outside the foreach)
        if(timelineTypes.includes(item.type)) {
          let nextItem = pos + 1 == arr.length ? null : arr[pos + 1]
          let prevItem = pos === 0 ? null : arr[pos - 1]

          if(item.type == 'Q' && prevItem?.type != 'Q'){
            merged.push([{...item}])
            if(nextItem?.type != 'Q')
              idx++
          }
          else if(item.type == 'Q' && prevItem.type == 'Q'){
            merged[idx].push({...item})
          }
          else{
            merged.push({...item})
            idx++            
          }
        }
      })

      this.timelineItems = merged
    },
    generateTimelineItems(){
      this.timelineItems = _(this.flatItemlist.filter(i => i.timelineGroup))
            .groupBy(x => x.timelineGroup)
            .map((value, key) => ({groupId: key, items: value, duration: _.maxBy(value, 'duration')?.duration, type: value[0].type, mode: value.find(v => v.timelineMode?.toLowerCase() == 'any') ? 'any' : 'all'}))
            .value()
    },
    setTimelineItemDuration(item){
      const duration = Math.round(document.getElementById('timelineVideo'+item.groupId+this.segment.id).duration)
      if(item.duration != duration){
        this.$set(item, 'duration', duration)        
      }
    },
    videoLoadingError(item){ 
      let icon = document.getElementById('timelineIcon'+item.groupId+this.segment.id)
      icon.classList.add('error')
      icon.setAttribute('title', `Error when loading video: "${(this.selectedSession?.webRoot ?? this.selectedSessionStructure?.webContentRootPath ?? '') + item.items[0].fname}"`)
      this.$refs.snackbar.show(`Error when loading video: "${(this.selectedSession?.webRoot ?? this.selectedSessionStructure?.webContentRootPath ?? '') + item.items[0].fname}"`, 5000)
    },
    getTimelineItemStyle(item){
      let style = `${item.duration ? `flex-grow:${item.duration};` : ''}`
      if(item.progress && !isNaN(item.progress) && item.duration)
        style += `border-image: linear-gradient(to right, #4cad82 0%, #4cad82 ${item.minProgress}%, #ff8741 ${item.minProgress}%, #ff8741 ${item.maxProgress}%, grey ${item.maxProgress}%, grey 100%) 1;`
      return style
    },
    getTimelineItemTitle(item){  
      let title = ''    
      switch (item.type) {
        case 'vid':
          title = `Video (${item.items[0].title ?? 'no title'})`
          break
        case 'textVid':
          title = `Video (${item.items[0].title ?? 'no title'})`
          break
        case 'Q':
          title = `${item.items.length} Question${item.items.length > 1 ? 's' : ''}`
          break
        default:
          title = 'Interaction' 
          break       
      }
      return `${title} (${Math.floor(isNaN(item.progress) ? 0 : item.progress)}%) ${item.usersCompleted}/${item.usersTotal} completed`
    },
    async reinviteUsers(){
      await axios.post(`sessions/${this.selectedSession.id}/reinviteusers`)
      this.$refs.snackbar.show("All devices have been re-invited to this session successfully!")
    },
    async setTestModeUsers(){
      if(!this.selectedSession)
        return   
      await axios.post(`sessions/${this.selectedSession.id}/testmodeusers`, this.testUserIds)
      this.testModeSelector = false 
    },   
    async archiveResults(){
      if(!this.selectedSession)
        return      
      await axios({ url: `sessions/${this.selectedSession.id}/archiveresults`, method: "POST" })   
      this.results = []  
      //this.heatmapItemId = null      
      await this.getResultsGroups()
    },
    async exportFlattenedResults(){
      if(!this.selectedSession)
        return               
      this.$refs.results.export2_1(`session${this.selectedSession.id}-segment${this.segment.id}-results-export_grouped`, this.externalUserId)
    },
    async checkSession(){      
      try{
        this.usersInDifferentSession = null
        this.startSessionModal = true
        this.loading = true
        this.$nprogress.start()
        if(!this.selectedSession)
          return      
        let resp = await axios.get(`sessions/${this.selectedSession.id}/check`)  
        this.usersInDifferentSession = resp.data            
      }
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async startSession(){
      try{
        this.loading = true
        this.$nprogress.start()
        if(!this.selectedSession)
          return      
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/start?testMode=${this.testMode}`, method: "POST" })
              
        this.current.clock = this.selectedSession.clock = resp.data.clock
        this.current.status = this.selectedSession.status = 1
        this.current.allocation = undefined
        this.current.segmentStarted = undefined

        this.selectedSession.testMode = this.testMode
        
        this.users.forEach(user => {
          this.$set(user, 'condition', undefined)
          this.$set(user, 'progress', 0)
        }); 
      }
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        setTimeout(() => window.dispatchEvent(new Event('resize')), 500) // so heatmap can adjust itself
      }
    },
    async closeSession(){
      try{
        this.loading = true
        this.$nprogress.start()

        this.current = { 
          status: undefined,
          segment: undefined,
          conditions: undefined,
          allocation: undefined,
          segmentStarted: undefined
        }

        let resp = await axios({ url: `sessions/${this.selectedSession.id}/close`, method: 'POST' }) 
        
        this.current.clock = this.selectedSession.clock = resp.data.clock
        this.current.status = 0
        this.selectedSession.testMode = false
             
        this.users.forEach(user => {
          this.$set(user, 'condition', undefined)
          this.$set(user, 'progress', 0)
        });  

        this.$router.push('/upcoming')
      }
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async playSegment(){
      this.current.segment = this.segment
      this.current.status = 1
      if(this.conditionsWithRequirements)
       this.current.conditions = this.conditionsWithRequirements.map(c => ({condition: c.condition, id: c.id, seg: c.seg, txt1: c.txt1, requirements: c.requirements }))
      else
       this.current.conditions = this.conditions ? this.checkedConditions.map(c => ({condition: c.condition, id: c.id, seg: c.seg, txt1: c.txt1 })) : undefined  
      this.current.allocation = 'Automatic'

      this.users.forEach(u => {
        this.$set(u, 'progress', 0)
      });
      
      // call server, so it can notify devices      
      let segmentIds =  this.current.conditions ? this.current.conditions.map(c => ({id: c.id, file: c.seg, requirements: c.requirements})) : [({id: this.segment.id, file: this.segment.seg })]
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/play`, data: segmentIds, method: 'POST' })
      console.log("ALLOCATION: ")
      console.log(resp.data)
      
      this.current.clock = this.selectedSession.clock = resp.data.clock
      this.current.segmentStarted = resp.data.segmentStarted
      
      resp.data.allocations.forEach(allocation => {
        let user = this.users.find(u => u.id == allocation.userId)
        //this.$set(user, 'condition', this.current.conditions.find(c => c.id == allocation.condition.split('|')[0]).condition)
        this.$set(user, 'condition', allocation.condition)
      });      
    },
    async exitSegment(){
      this.current = { 
        status: 1,
        segment: undefined,
        conditions: undefined,
        allocation: undefined,
        segmentStarted: undefined
      }

      // call server, so it can notify devices            
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/stop`, method: 'POST' })     
      //console.log(resp.data)
      
      this.current.clock = this.selectedSession.clock = resp.data.clock
      // update device cards            
      this.users.forEach(user => {
        this.$set(user, 'condition', undefined)
        this.$set(user, 'progress', 0)
        Object.keys(user.metadata).forEach(key => {
          if(key.startsWith('cp_'))
            this.$delete(user.metadata, key)
        })    
      })      
    },
    async pauseSession(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/pause`, data: `"${this.pauseSessionMessage}"`, method: 'POST', headers: {'Content-Type': 'application/json'}})     
      //console.log(resp.data)
      /*this.selectedSession.status = */this.current.status = resp.data.status
      /*this.selectedSession.message = */this.current.message = resp.data.message
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async resumeSession(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/resume`, method: 'POST' })     
      //console.log(resp.data)
      /*this.selectedSession.status = */this.current.status = resp.data.status
      /*this.selectedSession.message = */this.current.message = resp.data.message
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async selfPacedOn(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/selfpacedon`, method: 'POST' })     
      //console.log(resp.data)
      this.current.status = resp.data.status
      this.users.forEach(user => {
        this.$set(user, 'condition', undefined)
      });
      this.current = { // may go to Vuex store later if needs persistence
        status: resp.data.status,
        segment: undefined,
        conditions: undefined,
        allocation: undefined,
        segmentStarted: resp.data.segmentStarted
      }
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async selfPacedOff(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/selfpacedoff`, method: 'POST' })     
      //console.log(resp.data)
      this.current.status = resp.data.status
      this.current.segmentStarted = resp.data.segmentStarted
      // this.users.forEach(user => {
      //   this.$set(user, 'condition', undefined)
      // });
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async getResults(){
      if(!this.selectedSession)
        return      
      
      this.loading = true
      this.$nprogress.start()
      
      let query = ''  
      switch (this.resultsSerial) {
        case "-1":
          query = ''
          break;
        case null:
          query = '?archived=false'
          break;      
        default:
          query = `?serial=${this.resultsSerial}`
          break;
      }   

      let resp = await axios({ url: `sessions/${this.selectedSession.id}/results${query}` }) 
      this.results = resp.data

      this.loading = false
      this.$nprogress.done()
      
    },
    async getResultsGroups(){
      if(!this.selectedSession)
        return 
      let resp = await axios.get( `sessions/${this.selectedSession.id}/resultsgroups`)
      this.resultsGroups = resp.data
    },
    devicesReady(includeOffHead){
      if(this.ticker < 0) // force update computed on ticker
        console.log(""); 
      return this.users.filter(u => u.device && u.device.timestamp && (includeOffHead || u.device.onhead) && (this.ticker-u.device.timestamp<this.$constants.deviceOfflineTimeout)) 
    }, 
    // fileIntegrityDetails(files){
    //   return files.map(f => {
    //     let s3file = this.s3files.find(s => s.key == f.key)
    //     let reason = 'unknown'
    //     if(s3file)
    //       reason = f.size < 0 ? 'missing' : `${f.size} instead of ${s3file.size} bytes`
        
    //     return {
    //       key: f.key,
    //       reason: reason
    //     }
    //   })
    // },
    setTreeParents(tree){
      if(tree.type == 'seg' || tree.type == 'list' || tree.type == 'conditional' || tree.condition)
        this.selectedSessionIdMap.set(Number(tree.id), tree)
      if(tree.list && tree.list.length){              
          for(let n in tree.list){
              tree.list[n].parent = tree;
              this.setTreeParents(tree.list[n]);
          }
      }
    },
    treeItemSelected(item){
      if([1,2,3].includes(this.current.status) && this.current.segment)
        return;
      if(item != this.selectedTreeItem){
        this.checkedConditions = []        
        this.heatmapItem = undefined
      } 
      // select first condition by default
      if(item.type == 'conditional')
        this.selectedTreeItem = item.list.find(i => i.type == 'seg') 
      else
        this.selectedTreeItem = item  
    },   
    async getCurrentSession(){      
      let sessionId = this.$route.params.sessionId ?? localStorage.getItem('lastSessionId')
      let resp = sessionId ? await axios.get(`sessions/${sessionId}`) : null
      if(resp?.data){           
        this.selectedSession = resp.data
        this.selectedTreeItem = this.selectedSessionStructure // this computed getter is building the selectedSessionIdMap
        if(this.$route.params.treeId){                       
          setTimeout(() => this.selectedTreeItem = this.selectedSessionIdMap.get(Number(this.$route.params.treeId )), 800)
        }    
      }
    },  
    loadCurrentSessionState(){
      if(!this.selectedSession)
        return 
      let allocatedConditions = [...new Set(this.users.map(u => u.condition).filter(c => c))]
      //debugger
      this.current.conditions = allocatedConditions.length       
        ? this.current.conditions = allocatedConditions.map(c => ({condition: this.selectedSessionIdMap.get(Number(c))?.condition, id: c, txt1: this.selectedSessionIdMap.get(Number(c))?.txt1 }))      
        : undefined

      const staleIds = allocatedConditions.filter(c => !this.selectedSessionIdMap.get(Number(c)))
      if(staleIds.length)
        this.$refs.snackbar.show(`WARNING: Cannot find previously allocated segment/conditions: ${staleIds.join(',')}. The session was probably edited while running.`, 10000)
      
      if(allocatedConditions.length)
        this.current.segment = (allocatedConditions.length > 1)
          ? this.selectedSessionIdMap.get(Number(allocatedConditions[0]))?.parent
          : this.selectedSessionIdMap.get(Number(allocatedConditions[0]))      
      else
        this.current.segment = undefined


      if(this.current.segment)
        this.selectedTreeItem = this.current.conditions && this.current.segment.type == 'conditional'
          ? this.current.segment.list.find(i => this.current.conditions.find(c => c.id == i.id )) 
          : this.current.segment
      else{
        // select "first" segment by default on UI
        for (const item of this.selectedSessionIdMap.values()) {              
          if (item.type == 'conditional' || item.type == 'seg') {
            if (item.type == 'seg')
              this.selectedTreeItem = item
            else{
              // select first condition by default
              this.selectedTreeItem = item.list.find(i => i.type == 'seg')
            }
            break
          }
        }
      }   

      this.current.status = this.selectedSession.status
      this.current.clock = this.selectedSession.clock  
      this.current.segmentStarted = this.selectedSession.segmentStarted    
      this.current.allocation = 'Automatic'      

      this.checkedConditions = [];

      try{
          this.s3files = this.selectedSession.fileList ?? []        
      }
      // eslint-disable-next-line
      catch{
        this.s3files = []
      }
    },
    async getUsers(){
      if(!this.selectedSession)
          return;      
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/users` }) 
      //console.log("got users: " + resp.data) 
      this.users = resp.data
      this.testUserIds = this.users.filter(u => u.testMode).map(u => u.id)  
        
      this.users.forEach(user => {   
        if(user.device && this.selectedSession.fileList?.find(sf => !user.device.fileList?.find(df => df.key.substring(this.pathPrefixes.deviceRootPath.length) == sf.key.substring(this.pathPrefixes.webContentRootPath.length) && df.size == sf.size)))
          this.$set(user.device, 'hasError', true)    
      });
    },
    closeDropdown: function(e){
      if(!this.$refs.conditionsdropdown)
        return
      const withinBoundaries = e.composedPath().includes(this.$refs.conditionsdropdown)      
      if(this.$refs.conditionsdropdown.getAttribute('opened') != null && (e.keyCode == 27 || (e.keyCode == undefined && !withinBoundaries)))
        this.$refs.conditionsdropdown.removeAttribute('opened')
    },
    Heartbeat(){
      // sends heartbeat
      if(this.connection?.connectionState == "Connected") {
        this.connection.invoke("UserHeartbeat").catch(function (err) {
          console.error(err.toString());
        });
      }
      // called in an interval, checks and removes if timeout reached for some users (so in case of missed message), after X seconds user is greyed out, and after Y seconds it gets fully removed      
      this.dashboardUsers = this.dashboardUsers.filter(u => this.ticker-u.timestamp <= this.$constants.userOfflineTimeout*2)
    },
    onReconnecting(){
      console.log('reconnecting')
      this.reconnecting = true
    },
    onReconnected(){
      console.log('reconnected')
      this.reconnecting = false
    },
    async dashboardConnect(){
      // let resp = await axios({ url: "auth/devicelogin", data: {deviceId: "1", pin: this.tempPIN.toString() }, method: "POST" })
      // console.log("got device token: " + resp.data)

      let temptokenvar = this.$store.state.auth.token;
      let sessionId = this.selectedSession?.id
      this.connection = new HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_API_URL + 'devicehub?sessionId=' + sessionId,{
            accessTokenFactory: () => temptokenvar
        })
        //.configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();    
      
      //console.log("connection ID: " + this.connection.connectionId)

      this.connection.on("Message", function (message) {
        console.log(message);
      }); 
      this.connection.on("DashboardRefresh", this.DashboardRefresh)     
      this.connection.on("DeviceConnected", this.DeviceConnected)
      this.connection.on("DeviceDisconnected", this.DeviceDisconnected)     
      this.connection.on("UserDisconnected", this.UserDisconnected)     
      this.connection.on("UserConnected", this.UserConnected)     
      this.connection.on("UserHeartbeat", this.UserHeartbeat)     
      this.connection.on("AnswerSubmitted", this.AnswerSubmitted)  
      this.connection.on("DeviceUnlinked", this.DeviceUnlinked)
      //this.connection.on("SetSessionStatus", this.SetSessionStatus)
      this.connection.on("SessionBroadcast", this.SessionBroadcast)
      //this.connection.on("FileIntegrity", this.FileIntegrity)
      this.connection.on("FileListUpdated", this.FileListUpdated)
      this.connection.onreconnecting(this.onReconnecting)
      this.connection.onreconnected(this.onReconnected)

      await this.connection.start() 
    },
    async tryGetUserWithDevice(deviceId){
      if(!deviceId)
        return null
      let user = this.users.find(u => u.device && u.device.id == deviceId)
      if(!user){
        // previously unlinked device now sending data -> fetch user-device pairs for session and update user list
        let r = (await axios({ url: `sessions/${this.selectedSession.id}/users?deviceId=${deviceId}` })).data        
        if(r && r.length)
          user = this.users.find(u => !u.device && u.id == r[0].id)
        if(user)
          this.$set(user, 'device', r[0].device)
      }
      return user;
    },
    async DashboardRefresh(resp){
      let user = await this.tryGetUserWithDevice(resp.id)
      if(user){
        if(resp.status.battery && resp.status.battery > 0)
          this.$set(user.device, 'battery', resp.status.battery)

        if(resp.status.progress || resp.status.progress == 0)
          this.$set(user, 'progress', resp.status.progress)

        if(resp.status.contentId && resp.status.contentProgress)
          this.$set(user.metadata, `cp_${resp.status.contentId}`, resp.status.contentProgress)

        this.$set(user.device, 'onhead', resp.status.onHead)   
        this.$set(user.device, 'timestamp', Date.now())        
      }
      else
        console.log(`Received info from device with Id ${resp.id} but no user found in this session linked to this device`)
    },
    async DeviceConnected(device, connectionId){
      if(!device) 
        return
      console.log((new Date()).toLocaleString() + " device connected: " + device.id + ", connection Id: " + connectionId)
      let user = this.users.find(u => u.id == device.userId) //await this.tryGetUserWithDevice(id)
      if(user){
        this.$set(device, 'timestamp', Date.now())
        this.$set(user, 'device', device)         
      }
      else
        console.log(`Device connected with Id ${device.id} but no user found in this session linked to this device`)
    },
    async DeviceDisconnected(id, connectionId){
      if(!id) 
        return
      console.log((new Date()).toLocaleString() + " device disconnected: " + id + ", connection Id: " + connectionId)
      let user = await this.tryGetUserWithDevice(id)
      if(user)    
        this.$set(user.device, 'timestamp', null)      
      else
        console.log(`Device disconnected with Id ${id} but no user found in this session linked to this device`)
    },
    async DeviceUnlinked(id){
      console.log("device unlinked: " + id)
      if(!id) 
        return
      let user = await this.tryGetUserWithDevice(id)
      if(user)    
        this.$set(user, 'device', null)      
      else
        console.log(`Device unlinked with Id ${id} but no user found in this session linked to this device`)
    },
    UserConnected(connectionId, name){      
      // add or update connectionIds
      let user = this.dashboardUsers.find(u => u.connectionId == connectionId);
      if(user)
        user.timestamp = Date.now()
      else
        this.dashboardUsers.push({connectionId: connectionId, name: name, timestamp: Date.now() })
      // send heartbeat immediately so newly joined user pick it up
      this.connection.invoke("UserHeartbeat").catch(function (err) {
        console.error(err.toString());
      });
    },
    UserDisconnected(connectionId){
      // remove from connectionIds
      this.dashboardUsers = this.dashboardUsers.filter(u => u.connectionId != connectionId)
    },
    UserHeartbeat(connectionId, name){
      // refresh timestamp for given connectionId
      let user = this.dashboardUsers.find(u => u.connectionId == connectionId);
      if(user)
        user.timestamp = Date.now()
      else
        this.dashboardUsers.push({connectionId: connectionId, name: name, timestamp: Date.now() })
    },
     async AnswerSubmitted(answers){
      console.log("answer(s) submitted:")
      answers.forEach(element => {
        console.log(element)
        this.results.push(element)
      });
    },
    async SessionBroadcast(broadcast){      
      if(!this.selectedSession)
          return;
      if(this.current.clock < broadcast.clock)
      {
        if(this.current.status != broadcast.status && broadcast.status == 0)
          this.$refs.snackbar.show('Session has been closed or timed out due to inactivity')
        if(this.current.status != broadcast.status && broadcast.status > 0)
          this.$refs.snackbar.show('Session has been started')

        let resp = await axios({ url: `sessions/${this.selectedSession.id}/users` })       
        this.users.forEach(u => u.condition = resp.data.find(r => r.id == u.id).condition)
        this.selectedSession.status = broadcast.status
        this.selectedSession.clock = broadcast.clock
        
        let resp2 = await axios({ url: `sessions/${this.selectedSession.id}` })
        this.selectedSession.segmentStarted = resp2.data.segmentStarted
        this.selectedSession.metadata = resp2.data.metadata
        this.selectedSession.testMode = resp2.data.testMode
        if(broadcast.status == 2)
          this.current.message = resp2.data.message        
        else
          this.current.message = null          

        this.loadCurrentSessionState() 
      }      
    },
    async FileListUpdated(deviceId, files){
      if(!deviceId) 
        return
           
      let user = await this.tryGetUserWithDevice(deviceId)
      if(user)   {     
        this.$set(user.device, 'fileList', files)      
        console.log("files data received for device " + deviceId) 
        if(this.selectedSession.fileList?.find(sf => !files?.find(df => df.key.substring(this.pathPrefixes.deviceRootPath.length) == sf.key.substring(this.pathPrefixes.webContentRootPath.length) && df.size == sf.size)))
          this.$set(user.device, 'hasError', true)           
        else
          this.$set(user.device, 'hasError', false)           
      }
      else
        console.log(`Files data received for device with Id ${deviceId} but no user found in this session linked to this device`)                 
    },
    clock(){      
      this.secondTicker = Date.now()
      
      let seconds = Math.round((this.secondTicker - new Date(this.current.segmentStarted))/1000)
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      
      this.elapsedTime = (isNaN(seconds) || !this.current.segmentStarted)
        ? '00:00:00'
        : `${String(hours).padStart(2, '0')}:${String(Math.floor(minutes)).padStart(2,'0')}:${String(seconds % 60).padStart(2,'0')}`      
    },
    beforeWindowUnload(e){
      if (this.selectedSession?.status != 0 && this.selectedSession?.status != 4) {
        e.preventDefault()
        e.returnValue = 'something'
      }
    }
  },    
  async mounted() {
    this.loading = true
    this.$nprogress.start()    
    if(this.$route.params.sessionId)
      localStorage.setItem('lastSessionId', this.$route.params.sessionId)    
    await this.getCurrentSession()
    if(this.$route.query.testMode || this.selectedSession?.testMode)
      this.testMode = true
    await this.getUsers()
    this.loadCurrentSessionState()
    // await this.checkForUpdates()
    await this.getResults()   // TODO erwork into separate promises for large datasets to not hang UI
    await this.getResultsGroups()
    await this.dashboardConnect()  
    window.addEventListener("keyup", this.closeDropdown, false);
    window.addEventListener("click", this.closeDropdown, false); 
    window.addEventListener('beforeunload', this.beforeWindowUnload)
    this.$nprogress.done()
    this.loading = false  
    this.$store.state.showLoader = false
  },
  async created(){    
    this.secondTickerInterval = setInterval(this.clock, 1000)  
    this.tickerInterval = setInterval(() => {this.ticker = Date.now()}, 5000)   
    this.heartbeatInterval = setInterval(() => {this.Heartbeat()}, 15000)     
  },
  async beforeDestroy(){
    await this.connection.stop()  
    window.removeEventListener("keyup", this.closeDropdown, false);
    window.removeEventListener("click", this.closeDropdown, false);   
    window.removeEventListener('beforeunload', this.beforeWindowUnload)  
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)
    if(this.secondTickerInterval)
      clearInterval(this.secondTickerInterval)
    if(this.heartbeatInterval)
      clearInterval(this.heartbeatInterval)
  }
}
</script>

<style lang="scss">

main.dashboard{
  display: flex;
  font-family: 'Roboto';
  height: calc(100lvh - var(--size-header));
  width: 100%;

  .reconnecting{
    position: fixed;
    top: 0;
    left: 40%;
    right: 40%;
    text-align: center;
    z-index: 1000;
    padding: 4px;
    background-color: red;
    border-radius: $borderradius;
    color: white;
  }

  .starting{
    flex-grow: 1;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #eef2f5;
    background-color: #fff;
    height: calc(100lvh - var(--size-header) - 35px);
    margin-top: 15px;
    margin-left: 280px; //64px;
    padding: 40px 100px 34px 100px;
    position: relative;
    text-align: center;
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .leftside{
    //flex-basis: 220px;
    min-width: 215px;
    padding: 19px 0px 19px 24px;
    margin-right: 6px;
    overflow-y: overlay;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    > div a{
      padding: 0;
      color:black;
      font-size:18px;
      &:hover{
        color: $green1;
      }
    }

    .tutorial{
      margin-top:16px;

      .thumbnail{
        width: 162px;
        height: 87px;
        background-image: url(../assets/tutorial.svg);
        background-size: cover;
        border-radius: 4px;    
        cursor: pointer;  
        position: relative; 
        
        &::after{
          content: '';
          width: 34px;
          height: 34px;
          display: block;
          position: absolute;
          left: 64px;
          top: 27px;
          background-image: url(../assets/vimeo-play.svg);
        }

        &:hover{          
          box-shadow: inset 0 0 0 3px #86C596;
        }
        &:active{
          box-shadow: inset 0 0 0 3px #4CAD82;  
          &::after{
            display: none;
          }        
        }
      }

      .title{
        position: relative;
        width: 162px;
        padding-top: 8px;

        svg{
          position: absolute;
          right: 0;
          cursor: pointer;

          &:hover{          
            path{
              fill: #86c596;
            }
          }
          &:active{
            path{
              fill: #4cad82;
            }        
          }
        }
      }      
    }

    > ul{
      margin-top:0;
      flex-grow: 1;
      min-height: 100px;
      overflow-y: overlay;
      overflow-x: hidden;
      padding-right: 18px;
    }

    h2{
      margin: 0 0 16px 0;
      font-size: 18px;
      font-weight: bold;
    }

    &.playing {
      &::before{
        content: 'Stop playback of current segment to enable selecting another one.';
        position: fixed;
        max-width: 180px;
        left: 30px;
        top: 40lvh;
        z-index: 10000;
        background-color: #3C4448;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 3px;
        font-size: 14px;
        line-height: 16px;  
        opacity: 0; 
        transition: opacity 0.6s ease;
        pointer-events: none;
      }
      &:hover::before{
        opacity: 1;
        transition: opacity 1s ease;
        transition-delay: 0.5s;
      }

      div:not(.selected) .thumbnail img{
        opacity: 0.5;
      }
    }
  }

  .middle{
    flex-grow: 1;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    //background-color: aqua;
  }

  .rightside{
    //flex-basis: 260px;
    min-width: 260px;
    padding: 15px 10px 20px 30px;

    h2{
      margin: 0 0 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  header.v2{
    &.green{
      background-color: #86c596;
      .header-controls span{
        color:white;
      }
    }
    &.purple{
      background-color: #8a93fc;
      .header-controls span{
        color:white;
      }
    }
    &.testMode{
      background-color: #96e2cb;
    }
  }

  .header-controls{
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 70px;

    > div {
      flex-grow: 1;

      &:last-child{
        justify-content: right;
        display: flex;
      }
    }

    button.icon{
      margin-left: 10px;

      &.pause{
        background: url(../assets/pause.svg);    
        &:hover{
          background: url(../assets/pause-hover.svg);
        }     
        &:active, &.active{
          background: url(../assets/pause-active.svg);  
        }    
        &[disabled]/*, &[disabled]:hover*/{
          background: url(../assets/pause-disabled.svg); 
            cursor: unset;
        } 
        &.selected{
          background: url(../assets/pause-selected.svg);  
          background-size: contain; 
        }  
      }
      &.selfpace{
        background: url(../assets/selfpace.svg);   
        background-size: contain; 
        &:hover{
          background: url(../assets/selfpace-hover.svg);
          background-size: contain; 
        }     
        &:active, &.active{
          background: url(../assets/selfpace-active.svg);  
          background-size: contain; 
        }    
        &[disabled]/*, &[disabled]:hover*/{
          background: url(../assets/selfpace-disabled.svg); 
          background-size: contain; 
          cursor: unset;
        }  
        &.selected{
          background: url(../assets/selfpace-selected.svg);  
          background-size: contain; 
        }        
      }
    }
  }

  .segment{
    padding: 20px 0 20px 0;
    overflow: visible;
    display: flex;

    .image{
      width: 115px;
      img {
        width: 97px;
        height: 60px;
        border-radius: 4px;
      }
    }

    .text{
      //flex-grow: 1;
      justify-content: left;
    }

    .conditions{  
      //flex-grow: 1;
      justify-content: left;
      padding-right: 20px;
      padding-left: 50px;

      .dropdown{
        color: black;
        text-align: left;

        span.spacer{
          &:after{
            content: ', ';
            //padding: 0 10px;
          }
          &:last-of-type{
            //padding: 0;

            &:after{
              content: '';
              display: none;
            }
          }
        }

        span.letter{
          // border-radius: 5px;
          // background-color: #E9F4EA; 
          // color: $green1;
          // padding: 2px 5px;  
          // //margin-right: 10px;  
          // font-weight: bold;   
          // font-size: 12px;
          // line-height: 14px;      
        }
      }
    }  

    span.letter{
      border-radius: 10px;
      color: white;
      background-color: #57af80;
      padding: 0 10px;
      font-size: 14px;
      margin:0 2px;
    }

    .overallprogress{
      flex-grow: 1;
      padding-left:50px;

      .title{
        color: #585c5e;
      }
      .progressbar{        
        height: 25px;
        background: rgba(195, 211, 223, 0.5);
        border: solid 1px #d5d5d6;
        margin-top: 5px;
        line-height: 25px;
        text-align: center;
        font-size: 16px;
        position: relative;

        span{
          position: absolute;
          left: 45%;
        }

        .progress{
          height: 30px;
          background-color: #58b7db;
        }
      }
      span.help{
        display: inline-block;
        width:20px;
        height:22px;
        margin-left: 8px;
        background: url(../assets/help.svg);
        cursor: help;
        vertical-align: middle;
      }
    }

    .controls{
      display: flex;
      align-items: center;
      justify-content: left;
      //flex-grow: 1;  
      padding-left: 40px;

      button{
        width: 40px;
        height: 40px;
        padding: 0;
        min-width: 40px;
        margin-left: 10px;
        border: none;   
        background-size: contain; 

        &.play{
          background: url(../assets/play-icon2.svg);    
          &:hover{
            background: url(../assets/play-hover.svg);
          }     
          &:active, &.active {
            background: url(../assets/play-active.svg);  
          }    
          &[disabled], &[disabled]:hover{
            background: url(../assets/play-disabled.svg); 
              cursor: unset;
          } 
        }
        &.stop{
          background: url(../assets/stop.svg);   
          background-size: contain; 
          &:hover{
            background: url(../assets/stop-hover.svg);
            background-size: contain; 
          }     
          &:active, &.active{
            background: url(../assets/stop-active.svg);  
            background-size: contain; 
          }    
          &[disabled], &[disabled]:hover{
            background: url(../assets/stop-disabled.svg); 
            background-size: contain; 
            cursor: unset;
          } 
        }
      }
    }
  }

  .condition-tabs{
    height: 40px;
    border-radius: 8px 8px 0 0;
    border: solid 1px #eef2f5;
    background-color: #f6f9fb;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    span{
      display: inline-block;
      min-width: 120px;
      padding: 10px 24px 0 24px;
      cursor: pointer;
      font-size: 18px;
      height: 40px;
      margin-left: -1px;
      border: solid 1px transparent;

      &.active{
        border-radius: 8px 8px 0 0;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #eef2f5;
        background-color: #fff;
        position: relative;

        &:after{
          content: ' ';
          background-color: white;
          height: 8px;
          position: absolute;
          bottom: -8px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .timeline{
    padding: 20px 24px;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #eef2f5;
    background-color: #fff;
    margin-bottom: 14px;
    height: 110px;
    position: relative;
    user-select: none;   

    h2{
      font-size: 18px;
      margin:0;

      span.help{
        display: inline-block;
        width:18px;
        height:20px;
        margin-left: 8px;
        background: url(../assets/help.svg);
        background-size: contain;
        background-repeat: no-repeat;
        cursor: help;
        vertical-align: top;
        position: relative;

        &:hover > div {
          display: block;
        }

        > div{
          display: none;
          position: absolute;
          left: 40px;
          top: 0;
          background-color: white;
          border-radius: 5px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
          padding: 10px;
          z-index: 12;
          font-size: 14px;
          white-space: nowrap;

          .color, img{
            width: 20px;
            height: 20px;
            margin-left: 10px;
            vertical-align: middle;
          }

          th{
            text-align: left;
            font-weight: bold;
          }

          td, th{
            padding: 5px;
          }
        }
      }
    }
    video{
      display:none;
    }

    &.hasconditions{
      //border-radius: 0 0 8px 8px;
    }

    .chart{
      display: flex;
      gap: 10px;
      position: absolute;
      bottom: 10px;
      left: 24px;
      right: 24px;
      overflow-x: overlay;
      padding-bottom: 10px;

      .item{
        box-sizing: content-box;
        height:20px;
        padding-bottom: 6px;
        min-width: 24px;
        position: relative;
        display: flex;        

        &:not(.duration)::after{
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 0px;
          border-radius: 2px;
          border: 4px solid grey;
          bottom: -8px;
          //left: calc(50% - 4px);
        }

        &.duration{
          text-align: right;
          border-radius: 2px;
          border-bottom: 8px solid grey;
          min-width:60px;
        }

        &.inProgress, &.inProgress::after{
          border-color: #ff8741;
        }

        &.finished, &.finished::after{
          border-color: #4cad82;
        }

        .icon{
          text-align: center;
          flex-grow: 1;
          img{
            height: 20px;
          }
          &.error{
            border-radius: 5px;
            background-color: red;
          }
        }
        .time{
          text-align: right;
        }

      }
    }
  }

  .analytics{
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #eef2f5;
    background-color: #fff;
    margin-bottom: 20px;
    height: calc(100lvh - var(--size-header) - 245px);
    position: relative;

    &.hasconditions{
      height: calc(100lvh - var(--size-header) - 285px);
      border-radius: 0 0 8px 8px;
      .data{
        //height: calc(100lvh - var(--size-header) - 285px - 50px - 35px); // 35 a marginok
      }
    }

    h2{
      font-size: 18px;
      margin:0;
    }

    .ribbon{
      height: 50px;
      border-bottom: solid 1px #eef2f5;
      display: flex;
      justify-content: space-between;

      .tabs{
        display: flex;
        height: 50px;
        align-items: flex-end;

        span{
          display: inline-block;
          font-size: 18px;
          padding: 18px 10px 10px 10px;
          cursor: pointer;

          &:hover{
            color: $green1;
          }

          &.active{
            color: $green1;
            border-bottom: 2px solid $green1;
          }
        }
      }

      .buttons{
        display: flex;
        align-items: center;
        padding-right: 14px;
        gap:15px;

      }
    }

    .data{
      overflow-y: overlay;
      overflow-x: auto;
      // height: calc(100lvh - var(--size-header) - 245px - 50px - 35px); // 35 a marginok  
      // margin: 20px 6px 15px 20px; 
      position: absolute;
      top: 70px;
      bottom: 15px;
      left: 20px;
      right: 6px;
      height: unset;
    }

    &.expanded{
      position: fixed;
      top: calc(var(--size-header) + 20px);
      left:20px;
      bottom: 18px;
      right: 20px;
      margin: 0;
      height: auto;
      z-index: 20;
    }
  }

  .devicebar{
    // width: 250px;
    // min-width: 250px;
    font-weight: bold;
    font-size: 12px;
    position: relative;

    // .spinner{
    //   top: 15px;
    // }

    .counter{
      font-size: 18px;
      margin-bottom:15px;
      padding-right: 12px;
      // position: relative;

      // > div{
      //   position: absolute;
      //   right: 0;
      //   top: -6px;
      //   cursor: pointer;
      //   opacity: 0.5;
      //   font-size: 16px;
      //   &:hover{
      //     opacity: 1;
      //     transform: scale(1.1);
      //   }
      // }

      // &.errordetails{
      //   margin-bottom: 10px;
      //   border-bottom: 1px dashed #cecece;
      // }

      // a{
      //   font-size: 13px;
      // }

      .filter{
        cursor: pointer;     
        &.inactive, &.inactive span{
          color: #a2a2a2 !important;
        }           
      }
    }

    .devicecard{
      // border: $bordermixin;
      // box-shadow: $shadowM;
      padding: 18px 12px 16px 42px;
      margin: 8px 0 8px 0;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      background-image: url("../assets/headset-icon-orange.svg");
      background-size: 26px 20px;
      background-repeat: no-repeat;
      //background-position-x: 12px;
      background-position-y: 16px;
      transition: opacity 0.5s ease;
      position: relative;

      &:first-of-type{
        margin-top: 0;
      }

      .condition{
        text-align: center;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        left: 12px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: #323f5b;
      }
      .name{
        flex-grow: 1;
        padding-right:10px;
      }

      &.testMode{
        &:before{
          content: '⬤';
          position: absolute;
          color: #96e2cb;
          left: 30px;
        }
      }

      &.hasError{
        padding-bottom: 10px;
        cursor: pointer;

        .tooltiptext{
          position: fixed;
          right: 265px;
          top: 100px;
          bottom: unset;
          left: unset;
          transform: none;
          text-align: left;
        }
      }
      .error{
        color: red;
        font-weight: bold;
        font-size:12px;
        clear: both;
        //margin-top: 2px;
      }

      &.offline{
        opacity: 0.5;
        transition: opacity 0.5s ease;
      }

      &.onhead{
        background-image: url("../assets/headset-icon.svg");
      }

      &.unlinked{
        background-image: url("../assets/headset-unlinked-icon.svg");
      }      

      .battery{
        float: right;        
        background-image: url("../assets/battery-frame.svg");
        background-size: 19px 10px;
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 23px;     
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;   
        color: $green1;
        position: relative;

        .percentage{
          height: 6px;
          width: 14px;
          background-color: $green2;
          position: absolute;
          right: 3px;
          top: 5px;
          transform-origin: left;
          transform: scaleX(0.6);
        }

        &.red{
          color: $red;
          background-image: url("../assets/battery-frame-red.svg");

          .percentage{
            background-color: $red;
          }
        }
      }
    }

    &.readyFilter .devicecard{ 
      &.hasError, &:not(.onhead), &.offline, &.unlinked{
        display: none;
      }
    }
    &.errorFilter .devicecard:not(.hasError){
      display: none;
    }
  }

}   

</style>
